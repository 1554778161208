






















import { Component, Vue } from 'vue-property-decorator';
import Icon from '@/shared/resources/components/Icon.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
    Icon,
  },
})
export default class ActionsDropdown extends Vue {
  /**
   * Methods
   */
  private onButtonClick(e: Event) {
    e.preventDefault();
  }
}
